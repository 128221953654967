import '../styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import dynamic from "next/dynamic";
import Head from 'next/head';
const Whatsapp = dynamic(() => import("../components/whatsapp-button"));
const DelhiWhatsapp = dynamic(() => import("../components/delhi-whatsapp-button"));
import { useRouter } from 'next/router';

export default function App({ Component, pageProps }) {

  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
  }, []);

  useEffect(() => {
    console.log(router.pathname.includes("/delhi/"), router.pathname);
    if (
      window.location.href.includes("/delhi/")
    ) {
      setIsLoading(true);
      console.log(window.location.href, "whatsapp");
    }
    else if (
      window.location.href.includes("/sch-test/")
    ) {
      setIsLoading(true);
    }
    else {
      setIsLoading(false);
    }
  }, [router.pathname]);


  return (
    <>
      {!isLoading &&
        <Head>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-7BBP512TZQ"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());            
              gtag('config', 'G-7BBP512TZQ');
            `}
          </script>
        </Head>
      }

      {isLoading &&
        <Head>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-2ST7ZJ1RE5"></script>
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'G-2ST7ZJ1RE5');
            `}
          </script>
        </Head>
      }

      <title>Shankar IAS Academy</title>

      <Component {...pageProps} />
      <ToastContainer
        theme='dark'
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
      {!isLoading &&
        <Whatsapp />
      }
      {isLoading &&
        <DelhiWhatsapp />
      }
    </>)
}
